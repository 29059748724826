import gql from 'graphql-tag'
import candidateFragment from './CandidateFragment'

export const REMOVE_CERTIFICATE_MUTATION = gql`
  mutation RemoveCertificate($index: Int!,$candidateId: ID) {
    removeCertificate(index: $index , candidateId: $candidateId)
  }
`

export const UPDATE_CANDIDATE_ACCOUNT_MUTATION = gql`
  mutation UpdateCandidateAccount($candidateInput: CandidateInput) {
    updateCandidateAccount(candidateInput: $candidateInput) {
      ...${candidateFragment}
    }
  }
`
 
export const UPLOAD_CENTIFICATE_MUTATION = gql`
  mutation UploadCertificate($certificate: Upload!,$candidateId: ID) {
    uploadCertificate(certificate: $certificate,candidateId:$candidateId)
  }
`